import React, { useState, useRef } from "react";
import axios from "axios";
// import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import Swal from 'sweetalert2';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import { useMountEffect } from 'primereact/hooks';
import { Messages } from 'primereact/messages';
import { Panel } from 'primereact/panel';
import { FloatLabel } from "primereact/floatlabel";
import { InputNumber } from 'primereact/inputnumber';
import { FileUpload } from 'primereact/fileupload';
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import LoadingDialog from "../../views/LoadingView";

async function getMe() {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/me`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
};

async function getShop(shop_id) {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/shop/${shop_id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
};

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body - 2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Item2 = styled(Paper)(({ theme }) => ({
    backgroundColor: '#ECD400',
    ...theme.typography.body - 2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '25rem'
}));

const Item3 = styled(Paper)(({ theme }) => ({
    ...theme.typography.body - 2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flexGrow: 2,
}));

export default function WalletView() {
    const [isLoading, setisLoading] = useState(false);

    const [position, setPosition] = useState('');
    const [member, setMember] = useState({});
    const [shop, setShop] = useState({});
    const [amount, setAmount] = useState(null);
    const [imagePreView, setImagePreview] = useState();
    const [image, setImgae] = useState();
    const [checked, setCheked] = useState(false);

    const msgs = useRef(null);

    useState(async () => {
        const response = await getMe();
        if (response.logout === true) {
            localStorage.clear();
            window.location.href = "/";
        }
        if (response.level === 'member') {
            setMember(response.data);
            setPosition('One Stop Platform');
        } else if (response.level === 'employee') {
            if (response.data.employee_position !== 'manager') {
                Swal.fire({
                    title: "แจ้งเตือน?",
                    icon: "warn",
                    text: 'ผู้ใช้งานดังกล่าวไม่สามารถใช้งานระบบนี้ได้',
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "ยืนยัน",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.href = "/";
                    }
                })
            } else {
                const shop = await getShop(response.data.employee_shop_id);
                if (shop.data.shop_status === false) {
                    Swal.fire({
                        title: 'แจ้งเตือน!',
                        text: 'ร้านค้าของท่านถูกปิดการใช้งาน กรุณาติดต่อแอดมิน',
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                } else {
                    setShop(shop.data);
                    setPosition('One Stop Service');
                }
            }
        }
    });

    const headleSetImage = (e) => {
        const file = e.files;
        if (file) {
            const img_size = file[0].size;

            if (img_size > 500000) {
                Swal.fire({
                    title: 'แจ้งเตือน!',
                    text: 'รูปดังกล่าวมีขนาดใหญ่เกินไป กรุณาเปลี่ยนรูป',
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 1500,
                });
                return false;
            }
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file[0]);
            fileReader.addEventListener("load", (event) => {
                // this.img_preview = event.target.result;
                setImagePreview(event.target.result)
            })
            setImgae(file)
        }
    };

    const heandleResetImage = (e) => {
        setImagePreview();
    };

    const headleCopy = async () => {
        const number = '8162832951';
        await navigator.clipboard.writeText(number);
        Swal.fire({
            title: 'สำเร็จ',
            text: 'คัดลอกเลขที่บัญชีสำเร็จ',
            icon: 'success',
            showConfirmButton: false,
            timer: 3000,
        });
    };

    const headleSendWallet = async (e) => {
        e.preventDefault();
        if (!checked) {
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'กรุณาอ่านข้อความข้างต้นและยอมรับเงื่อนไข',
                icon: 'warning',
                showConfirmButton: false,
                timer: 1500,
            });
            return false;
        }
        if (amount === 0 || amount === null) {
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'กรุณากรอกจำนวนเงิน',
                icon: 'warning',
                showConfirmButton: false,
                timer: 1500,
            });
            return false;
        }
        if (!imagePreView) {
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'กรุณาแนบหลักฐานการโอน',
                icon: 'warning',
                showConfirmButton: false,
                timer: 1500,
            });
            return false;
        }

        const formdata = new FormData();
        formdata.append("amount", amount);
        formdata.append("slip_image", image[0]);
        formdata.append("payment_type", position);

        const token = JSON.parse(localStorage.getItem('token'));
        setisLoading(true);
        await axios.post(`${process.env.REACT_APP_SHOP}/wallet/slip`, formdata, {
            headers: {
                'auth-token': `Bearer ${token}`
            }
        }).then(() => {
            Swal.fire({
                title: 'สำเร็จ!',
                text: 'เคิมเงินสำเร็จ กรุณาตรวจสอบการทำรายการ',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500,
            });
            setisLoading(false);
            setAmount(0);
            setImagePreview();
            setCheked(false);
        }).catch((err) => {
            console.log(err)
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: err.response.data.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
            setisLoading(false);
        })
    };

    useMountEffect(() => {
        if (msgs.current) {
            msgs.current.clear();
            msgs.current.show([
                {
                    id: '1',
                    sticky: true,
                    severity: 'info',
                    closable: false,
                    content: (
                        <React.Fragment>
                            <div style={{ fontFamily: 'Kanit' }}>
                                <strong>เวลาทำการ : </strong>เวลาทำการตรวจสอบแจ้งเติมเงิน ทุกวัน เวลา 9.00 น. ถึง 18.00 น. หากนอกเวลาทำการจะทำการตรวจสอบในเวลาทำการของวันถัดไป
                            </div>
                        </React.Fragment>
                    ),
                },
                {
                    id: '2',
                    sticky: true,
                    severity: 'warn',
                    closable: false,
                    content: (
                        <React.Fragment>
                            <div style={{ fontFamily: 'Kanit' }}>
                                <strong>สำคัญ : </strong>ยอดเงินขั้นต่ำในการโอนอย่างน้อย 100 บาท ขึ้นไป หากโอนยอดผิดหรือน้อยกว่านี้ กระบวนการตรวจสอบอาจใช้เวลานาน หรือรายการจะถูกยกเลิกทันที
                            </div>
                        </React.Fragment>
                    ),
                },
            ]);
        }
    });

    return (
        <div>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box>
                            <Grid container className={"container"}>
                                <div className="title" ><h1>เติมเงินกระเป๋าอิเล็กทรอนิกส์</h1></div>
                                <Box sx={{ width: '100%' }}>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={12}>
                                            <Grid className="form">
                                                <Messages ref={msgs} />
                                            </Grid>
                                        </Grid>
                                        <Grid item className="wallet-form">
                                            <Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>STEP 1 : โอนเงินเข้าบัญชีบริษัท</Item>
                                            <Item2 sx={{ borderTopRightRadius: '0', borderTopLeftRadius: '0' }}>
                                                <Stack spacing={{ xs: 2 }} sx={{ width: '100%', justifyContent: 'center' }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
                                                    <div>
                                                        <img src={require('../../assets/bank/SCB.png')} alt="img" style={{ width: '100%' }} />
                                                    </div>
                                                    <div>
                                                        <Button label="คัดลอก" onClick={headleCopy} />
                                                    </div>
                                                </Stack>
                                            </Item2>
                                        </Grid>
                                        <Grid item className="wallet-form">
                                            <Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>STEP 2 : กรอกจำนวนเงิน และ แนบหลักฐานการโอน</Item>
                                            <Item2 sx={{ borderTopRightRadius: '0', borderTopLeftRadius: '0' }}>
                                                <Stack spacing={{ xs: 2 }} sx={{ width: '100%', justifyContent: 'center' }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
                                                    <Item3 sx={{ padding: 0, margin: '1rem' }}>
                                                        <FloatLabel className="custom-InputText">
                                                            <InputNumber className="custom-InputText" id="amount" value={amount} onChange={(e) => setAmount(e.value)} />
                                                            <label htmlFor="amount">กรอกจำนวนเงิน</label>
                                                        </FloatLabel>
                                                    </Item3>
                                                    <div className="w-full mb-2">
                                                        {!imagePreView && (
                                                            <div className="justify-center">
                                                                <FileUpload
                                                                    mode="basic"
                                                                    name="demo[]"
                                                                    chooseLabel="แนบรูปหลักฐาน"
                                                                    auto
                                                                    customUpload
                                                                    accept="image/png, image/jpeg,image/jpg"
                                                                    maxFileSize={2097152}
                                                                    uploadHandler={headleSetImage}
                                                                />
                                                                {/* <button className="upload-button"> */}
                                                                {/* แนบหลักฐานการโอน */}
                                                                {/* </button> */}
                                                                {/* <input type="file" className="file-input" onChange={headleSetImage} /> */}
                                                            </div>
                                                        )}
                                                        {imagePreView && (
                                                            <React.Fragment>
                                                                <div>
                                                                    <img src={imagePreView} alt="img" className="file-img" />

                                                                </div>
                                                                <div style={{ marginTop: '2rem' }}>
                                                                    <Button label="เปลี่ยนรูป" onClick={heandleResetImage} />
                                                                </div>
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                </Stack>
                                            </Item2>
                                        </Grid>
                                        <Grid item className="wallet-form">
                                            <Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>STEP 3 : ยอมรับเงื่อนไขและสัญญา</Item>
                                            <Item2 sx={{ borderTopRightRadius: '0', borderTopLeftRadius: '0' }}>
                                                <React.Fragment>
                                                    <div>
                                                        <Checkbox inputId="condition" onChange={(e) => setCheked(e.checked)} checked={checked} />
                                                        <label htmlFor="condition">ยอมรับเงื่อนไขการและข้อความแจ้งเตือนข้างต้น</label>
                                                    </div>
                                                    <div style={{ margin: '1rem' }}>
                                                        <Button label="ส่งข้อมูลแจ้งเติมเงิน" icon="pi pi-send" onClick={headleSendWallet} />
                                                    </div>
                                                </React.Fragment>
                                            </Item2>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid >
            </Box >

            <LoadingDialog isLoading={isLoading} />
        </div >
    )
}